import { PUBLIC_CLERK_PUBLISHABLE_KEY } from '$env/static/public'
import type { HandleClientError } from '@sveltejs/kit'
import { initializeClerkClient } from 'clerk-sveltekit/client'
import { OverviewRoute, SignInRoute, SignUpRoute } from './routes/route'

initializeClerkClient(PUBLIC_CLERK_PUBLISHABLE_KEY, {
    afterSignInUrl: OverviewRoute,
    afterSignUpUrl: OverviewRoute,
    signInUrl: SignInRoute,
    signUpUrl: SignUpRoute,
})

export const handleError: HandleClientError = async ({ error, event }) => {
    console.error(error, event)
}
